.react-datepicker-popper {
  z-index: 9999 !important;
}

.react-datepicker {
  border-radius: 0;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 0;
  background-color: #243973;
  color: #fff;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  border-radius: 0;
  background-color: #243973;
  color: #fff;
}

.react-datepicker__header {
  background-color: #e6e6e6;
  border-radius: 0;
}

.react-datepicker__header--time {
  background-color: #e6e6e6;
  border-radius: 0;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  border-radius: 0;
  background-color: #f0f0f0;
  color: black;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: #243973;
  color: white;
  font-weight: bold;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected:hover {
  background-color: #243973;
}

.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border-top-right-radius: 0rem;
}

.react-datepicker__time-list::-webkit-scrollbar-thumb {
  background: #243973;
}

.react-datepicker__time-list::-webkit-scrollbar-track {
  background: transparent;
}
