.map {
  max-height: 100%;
}

.region {
  fill: #00713d;
  cursor: pointer;
}

.hover {
  stroke: #db3336;
  fill: white;
}

.tooltip {
  display: none;
}
