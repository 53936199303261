// Sidebar

.sidebar__item {
  transition: all 300ms linear 0s;
  background-size: 200% 100%;
  background-position: 100% 0%;
}

.sidebar__item:hover {
}

.sidebar__item--idle {
}

.sidebar__item--idle:hover {
  background: #343b4c;
}

.sidebar__item--active {
  background: #343b4c;
}

.sidebar__item--active:hover {
}

.sidebar__item--open {
  background: linear-gradient(
    to right,
    #343b4c,
    transparent 50%,
    transparent 100%
  );
  background-size: 200% 100%;
  background-position: 100% 0%;
}

.sidebar__item--open:hover {
  background-position: 0% 0%;
}

.sidebar__item--open--active {
  background: linear-gradient(
    to right,
    #343b4c,
    transparent 50%,
    transparent 100%
  );
  background-position: 0% 0%;
  background-size: 200% 100%;
}

.sidebar__item--open--active:hover {
}

.logo-arrow {
  transition: transform 300ms linear 0s;
}

.logo-arrow--rotate {
  transform: rotate(180deg);
}

.sidebar__group__name {
  animation: sidebar__group__name--animation 400ms linear 0s 1 normal none;
}

@keyframes sidebar__group__name--animation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

// sidebar

.sidebar {
  .sidebar__popup {
    $userIconContainer: -2rem;
    $padding: 1rem;
    $popupContainer: 2 * 1.25rem + 1rem + 0.25rem;
    top: calc($userIconContainer - $padding - $popupContainer);
  }
  .sidebar__content {
    height: calc(100vh - 7.5rem);
  }
}

@media only screen and (min-width: 1536px) {
  .sidebar {
    .sidebar__content {
      height: calc(100vh - 9rem);
    }
  }
}

.overlay {
  max-height: calc(100vh - 10.5px);
}
