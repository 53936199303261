@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

html {
  font-size: 14px;
}

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  background-color: #fafafb;
}

input {
  box-shadow: none !important;
}

// Text Selection
input[type="radio"] {
  color: #243973;
}

::selection {
  color: white;
  background: #243973;
}

// Native Scrollbar

::-webkit-scrollbar {
  width: 0.4rem;
  height: 0.4rem;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #111727;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #19223a;
}

// Input range

input[type="range"] {
  -webkit-appearance: none;
  width: 200px;
  height: 7px;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 5px;
  background-image: linear-gradient(#243973, #243973);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #243973;
  cursor: ew-resize;
  box-shadow: 0 0 2px 0 #555;
  transition: background 0.3s ease-in-out;
}

input[type="range"]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}
